import React from 'react';
import {PaginationProps} from './pagination.types';
import {PaginationWrapper, Button, PrevButton, NextButton, Dots} from './Pagitation.styles';
import {IconArrowDownComponent} from '../../../assets';

const Pagination = (props: PaginationProps) => {
  const {totalPages, page, onChange} = props;

  const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1);

  const onPrevButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (page > 1) {
      onChange((page - 1), e)
    }
  }

  const onNextButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (page !== totalPages) {
      onChange((page + 1), e)
    }
  }

  const getDisplayedPages = () => {
    if (totalPages <= 6) {
      return pageNumbers;
    }

    if (page <= 3) {
      return [...pageNumbers.slice(0, 4), '...', totalPages];
    }

    if (page >= totalPages - 2) {
      return [1, '...', ...pageNumbers.slice(-4)];
    }

    return [1, '...', page - 1, page, page + 1, '...', totalPages];
  };

  return (
    <PaginationWrapper>
      <PrevButton onClick={onPrevButton} disabled={page <= 1}>
        <IconArrowDownComponent/>
      </PrevButton>

      {getDisplayedPages().map((number, index) => {
        if (typeof number !== 'number') {
          return <Dots key={`ellipsis-${index}`}>...</Dots>;
        }

        const buttonColor = number === page ? 'primary' : 'inherit';

        return (
          <Button key={number} onClick={(e) => onChange(number, e)} color={buttonColor}>
            {number}
          </Button>
        );
      })}

      <NextButton onClick={onNextButton} disabled={page === totalPages}>
        <IconArrowDownComponent/>
      </NextButton>
    </PaginationWrapper>
  );
}

export default Pagination;